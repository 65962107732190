import BaseApi from "@/api/BaseUrl";
import { AxiosResponse } from 'axios';
import {UserInterface} from "@/api/!types/UserTypes";
import {PaginateInterface} from "@/api/!types/Base";

class Users extends BaseApi {
    constructor() {
        super();
    }

    list(
        page: number,
        query: string|null = null,
    ): Promise<AxiosResponse<PaginateInterface<UserInterface[]>>> {
        return this.axios.get(`/users-panel/?page=${page}${query ? `&${query}` : ''}`);
    }

    search(query: string): Promise<AxiosResponse<PaginateInterface<UserInterface[]>>> {
        return this.axios.get(`/users-panel/search/?query=${query}`);
    }

    detail(id: number): Promise<AxiosResponse<UserInterface>> {
        return this.axios.get(`/users-panel/${id}`);
    }
}

export default new Users();
