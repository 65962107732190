import BaseApi from "@/api/BaseUrl";
import { AxiosResponse } from 'axios';
import {
    AccountPayoutDetail,
    AccountPayoutType,
    AccountPayoutTypeWithUser,
    CreateAccountType, PayoutTransactionItemInterface,
    PayoutTransactionListInterface, SuccessPaidPayoutInterface,
} from "@/api/!types/PayoutTypes";
import {DefaultResponseType, DefaultResponseTypeWithData} from "@/!types/Common";

class Payouts extends BaseApi {
    constructor() {
        super();
    }

    accounts(): Promise<AxiosResponse<AccountPayoutTypeWithUser[]>> {
        return this.axios.get('/payouts/accounts/');
    }

    newAccount(data: CreateAccountType): Promise<AxiosResponse<DefaultResponseTypeWithData<AccountPayoutType[]>>> {
        return this.axios.post('/payouts/accounts/', data);
    }

    changeAccount(id: number): Promise<AxiosResponse<AccountPayoutType[]>> {
        return this.axios.put(`/payouts/accounts/${id}`);
    }

    accountDetail(id: string): Promise<AxiosResponse<AccountPayoutDetail>> {
        return this.axios.get(`/payouts/accounts/${id}`)
    }

    payouts(): Promise<AxiosResponse<DefaultResponseTypeWithData<PayoutTransactionListInterface>>> {
        return this.axios.get('/payouts/');
    }

    createPayout(accountId: number, amount: number): Promise<AxiosResponse<DefaultResponseType>> {
        return this.axios.post('/payouts/', {
            account_id: accountId, amount,
        });
    }

    removePayout(id: number): Promise<AxiosResponse<DefaultResponseTypeWithData<PayoutTransactionItemInterface[]>>> {
        return this.axios.delete(`/payouts/${id}`);
    }

    paidPayout(id: number): Promise<AxiosResponse<DefaultResponseTypeWithData<SuccessPaidPayoutInterface>>> {
        return this.axios.post(`/payouts/paid/${id}`);
    }
}

export default new Payouts();
