import BaseApi from "@/api/BaseUrl";
import { AxiosResponse } from "axios";
import { ReferalDashboardInterface, ReferalSourceType } from "@/api/!types/ReferalTypes";
import { DefaultResponseTypeWithData } from "@/!types/Common";

class Referals extends BaseApi {
    constructor() {
        super();
    }

    dashboard(month: number, year: number): Promise<AxiosResponse<ReferalDashboardInterface>> {
        return this.axios.get(`/referals/dashboard/${month}/${year}`)
    }

    newSource(code: string): Promise<AxiosResponse<DefaultResponseTypeWithData<ReferalSourceType[]>>> {
        return this.axios.post(`/referals/source/`, { code })
    }
}

export default new Referals();
