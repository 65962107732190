import { Module } from "vuex";

export interface ModalsState {
    modalName: string|null,
}

const modals: Module<ModalsState, any> = {
    state: {
        modalName: null,
    },
    mutations: {
        setModal(state: ModalsState, value: string): void {
            state.modalName = value;
        },
        removeModal(state: ModalsState): void {
            state.modalName = null;
        }
    },
    actions: {},
    getters: {},
}

export default modals;
