import Finance from '@/api/Finance';
import Users from '@/api/Users';
import Orders from "@/api/Orders";
import Blogs from "@/api/Blogs";
import Referals from "@/api/Referals";
import Characters from "@/api/Characters";
import {FinanceListInterface} from "@/api/!types/FinanceTypes";
import {App} from "vue";
import {AxiosResponse} from "axios";
import {UserInterface} from "@/api/!types/UserTypes";
import {PaginateInterface} from "@/api/!types/Base";
import {OrderItemViewInterface, OrderListInterface, OrderType} from "@/api/!types/OrderTypes";
import {BlogInterface, BlogShortInterface} from "@/api/!types/BlogTypes";
import {ReferalDashboardInterface, ReferalSourceType} from "@/api/!types/ReferalTypes";
import {DefaultResponseType, DefaultResponseTypeWithData} from "@/!types/Common";
import {
    AccountPayoutDetail,
    AccountPayoutType, AccountPayoutTypeWithUser,
    CreateAccountType,
    PayoutTransactionItemInterface, PayoutTransactionListInterface,
    SuccessPaidPayoutInterface,
} from "@/api/!types/PayoutTypes";
import Payouts from "@/api/Payouts";
import {CharacterWithSubscription} from "@/api/!types/CharacterTypes";

type ApiType = {
    finance: {
        list: (month: number, year: number) => Promise<AxiosResponse<FinanceListInterface>>,
    },
    orders: {
        list: (page: number, query: string|null) => Promise<AxiosResponse<OrderListInterface>>
        item: (id: number) => Promise<AxiosResponse<OrderItemViewInterface>>,
        byUser: (userId: number) => Promise<AxiosResponse<OrderType[]>>
    },
    users: {
        list: (page: number, query: string|null) => Promise<AxiosResponse<PaginateInterface<UserInterface[]>>>,
        search: (query: string) => Promise<AxiosResponse<PaginateInterface<UserInterface[]>>>,
        detail: (id: number) => Promise<AxiosResponse<UserInterface>>,
    },
    blogs: {
        list: (page: number, query: string|null) => Promise<AxiosResponse<PaginateInterface<BlogShortInterface[]>>>,
        search: (query: string) => Promise<AxiosResponse<PaginateInterface<BlogShortInterface[]>>>,
        detail: (id: number) => Promise<AxiosResponse<BlogInterface>>,
    },
    referals: {
        dashboard: (month: number, year: number) => Promise<AxiosResponse<ReferalDashboardInterface>>,
        newSource: (code: string) => Promise<AxiosResponse<DefaultResponseTypeWithData<ReferalSourceType[]>>>,
    },
    payouts: {
        accountList: () => Promise<AxiosResponse<AccountPayoutTypeWithUser[]>>,
        newAccount: (data: CreateAccountType) => Promise<AxiosResponse<DefaultResponseTypeWithData<AccountPayoutType[]>>>,
        accountDetail: (id: string) => Promise<AxiosResponse<AccountPayoutDetail>>,
        changeAccount: (id: number) => Promise<AxiosResponse<AccountPayoutType[]>>,
        list: () => Promise<AxiosResponse<DefaultResponseTypeWithData<PayoutTransactionListInterface>>>,
        createPayout: (accountId: number, amount: number) => Promise<AxiosResponse<DefaultResponseType>>,
        removePayout: (id: number) => Promise<AxiosResponse<DefaultResponseTypeWithData<PayoutTransactionItemInterface[]>>>,
        paidPayout: (id: number) => Promise<AxiosResponse<DefaultResponseTypeWithData<SuccessPaidPayoutInterface>>>
    },
    characters: {
        list: (userId: number) => Promise<AxiosResponse<CharacterWithSubscription[]>>,
    },
}

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $api: ApiType,
    }
}

export default {
    install: (app: App) => {
        app.config.globalProperties.$api = {
            finance: {
                list: (month: number, year: number) => Finance.getList(month, year),
            },
            orders: {
                list: (page: number, query: string|null = null) => Orders.list(page, query),
                item: (id: number) => Orders.item(id),
                byUser: (userId: number) => Orders.byUser(userId),
            },
            users: {
                list: (page: number, query: string|null = null) => Users.list(page, query),
                search: (query: string) => Users.search(query),
                detail: (id: number) => Users.detail(id),
            },
            blogs: {
                list: (page: number, query: string|null = null) => Blogs.list(page, query),
                search: (query: string) => Blogs.search(query),
                detail: (id: number) => Blogs.detail(id),
            },
            referals: {
                dashboard: (month: number, year: number) => Referals.dashboard(month, year),
                newSource: (code: string) => Referals.newSource(code),
            },
            payouts: {
                accountList: () => Payouts.accounts(),
                newAccount: (data: CreateAccountType) => Payouts.newAccount(data),
                accountDetail: (id: string) => Payouts.accountDetail(id),
                changeAccount: (id: number) => Payouts.changeAccount(id),
                list: (accountId: number, amount: number) => Payouts.payouts(),
                createPayout: (accountId: number, amount: number) => Payouts.createPayout(accountId, amount),
                removePayout: (id: number) => Payouts.removePayout(id),
                paidPayout: (id: number) => Payouts.paidPayout(id),
            },
            characters: {
                list: (userId: number) => Characters.list(userId),
            },
        }
    },
}
