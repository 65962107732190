import BaseApi from "@/api/BaseUrl";
import { AxiosResponse } from 'axios';
import { PaginateInterface } from "@/api/!types/Base";
import {BlogInterface, BlogShortInterface} from "@/api/!types/BlogTypes";

class Blogs extends BaseApi {
    constructor() {
        super();
    }

    list(
        page: number,
        query: string|null = null,
    ): Promise<AxiosResponse<PaginateInterface<BlogShortInterface[]>>> {
        return this.axios.get(`/blogs-panel/?page=${page}${query ? `&${query}` : ''}`);
    }

    search(query: string): Promise<AxiosResponse<PaginateInterface<BlogShortInterface[]>>> {
        return this.axios.get(`/blogs-panel/search/?query=${query}`);
    }

    detail(id: number): Promise<AxiosResponse<BlogInterface>> {
        return this.axios.get(`/blogs-panel/${id}/`);
    }
}

export default new Blogs();
