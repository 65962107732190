import { store } from "@/store";
import Auth from "@/api/Auth";
import modalNames from "@/data/modalNames";

export const checkAuthToken = async () => {
    if (!store.state.isAuth) {
        store.commit('setModal', modalNames.LOADER);
        await Auth.getXsrf();
        try {
          await store.dispatch('authByToken');
            store.commit('changeLayout', 'default')
            store.commit('removeModal');
        } catch (e) {
          store.commit('setModal', modalNames.AUTH);
        }
    }
}
