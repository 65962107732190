
import { Options, Vue } from 'vue-class-component';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { key } from "@/store";
import { useStore } from "vuex";

@Options({
  components: {
    InputText,
    Button,
  },
})
export default class Auth extends Vue {
  protected email = '';

  protected password = '';

  protected error = '';

  protected isDisabled = false;

  private store = useStore(key);

  async authHandler() {
    this.error = '';
    this.isDisabled = true;
    if (!this.email || !this.password) {
      this.error = 'Поля не заполнены';
      return;
    }
    try {
      await this.store.dispatch('authByEmail', {
        email: this.email,
        password: this.password,
      });
      this.isDisabled = false;
      location.reload();
    } catch (e) {
      this.isDisabled = false;
      this.error = 'Ошибка авторизации';
    }
  }
}
