import BaseApi from "@/api/BaseUrl";
import { AxiosResponse } from "axios";
import { CharacterWithSubscription } from "@/api/!types/CharacterTypes";

class Characters extends BaseApi {
    constructor() {
        super();
    }

    list(userId: number): Promise<AxiosResponse<CharacterWithSubscription[]>> {
        return this.axios.get(`/character-panel/list/${userId}`);
    }
}

export default new Characters();
