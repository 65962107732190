import BaseApi from "@/api/BaseUrl";
import { AxiosResponse } from 'axios';
import {OrderItemViewInterface, OrderListInterface, OrderType} from "@/api/!types/OrderTypes";

class Orders extends BaseApi {
    constructor() {
        super();
    }

    list(page: number, query: string|null = null): Promise<AxiosResponse<OrderListInterface>> {
        return this.axios.get(`/sale/orders/?page=${page}${query ? `&${query}` : ''}`);
    }

    item(id: number): Promise<AxiosResponse<OrderItemViewInterface>> {
        return this.axios.get(`/sale/orders/${id}/`);
    }

    byUser(userId: number):Promise<AxiosResponse<OrderType[]>> {
        return this.axios.get(`/sale/orders/by-user/?user_id=${userId}`);
    }
}

export default new Orders();
