import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import {store, key} from './store';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';


import "primevue/resources/themes/lara-dark-indigo/theme.css";
import 'primevue/resources/primevue.min.css';
import "primeicons/primeicons.css";

import apiService from "@/plugins/apiService";

const app = createApp(App)
app.use(store, key);
app.use(router)
app.use(apiService);
app.use(PrimeVue);
app.directive('tooltip', Tooltip);
app.mount('#app');
