import { InjectionKey } from 'vue'
import { createStore, Store, ActionContext } from 'vuex';
import modals, { ModalsState } from "@/store/modules/modals";
import { UserAuthInterface, RolesType } from "@/api/!types/AuthTypes";
import { AxiosError } from "axios";
import Auth from "@/api/Auth";

const Cookies = require('js-cookie')

export interface State {
  layout: string,
  isAuth: boolean,
  userData?: UserAuthInterface,
  modals: ModalsState,
}

type AuthByEmailType = {
  email: string,
  password: string,
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    layout: 'plug',
    isAuth: false,
    userData: undefined,
  } as State,
  mutations: {
    changeAuth(state: State, value: boolean): void {
      state.isAuth = value;
    },
    changeLayout(state: State, value: string): void {
      state.layout = value;
    },
    setUserData(state: State, value: UserAuthInterface): void {
      state.userData = value
    }
  },
  getters: {
    maxRole(state: State):RolesType|null {
      if (!state.userData) {
        return {
          id: 1,
          name: 'Посетитель',
          alias: 'visitors',
          rights: 400,
        };
      }
      let maxRole:RolesType|null = null;
      state.userData.roles.forEach((role) => {
        if (!maxRole || role.rights < maxRole.rights) {
          maxRole = role;
        }
      });
      return maxRole;
    }
  },
  actions: {
    async authByToken(context: ActionContext<State, State>): Promise<void> {
      try {
        const { data } = await Auth.authByToken();
        context.commit('setUserData', data);
        context.commit('changeAuth', true);
      } catch (e) {
        console.error(e);
        throw new Error(`Error: ${(e as AxiosError).message}`);
      }
    },
    async authByEmail(context: ActionContext<State, State>, payload: AuthByEmailType): Promise<void> {
      try {
        const { data } = await Auth.authByEmail(payload.email, payload.password);
        const { token, data: userData } = data;
        Cookies.set('token', token, {
          expires: 3600 * 24 * 365,
        })
        context.commit('setUserData', userData);
        context.commit('changeAuth', true);
      } catch (e) {
        console.error(e)
        throw new Error(`Error: ${(e as AxiosError).message}`);
      }
    },
  },
  modules: {
    modals,
  },
});
