import BaseApi from "@/api/BaseUrl";
import { AxiosResponse } from 'axios';
import { UserAuthInterface, AuthByEmailType } from "@/api/!types/AuthTypes";

class Auth extends BaseApi {
    constructor() {
        super();
    }

    public getXsrf():Promise<AxiosResponse> {
        return this.axios.get(`/sanctum/csrf-cookie`, {
            // @ts-ignore
            baseURL: process.env.VUE_APP_API_URL.substring(0, process.env.VUE_APP_API_URL.length - 9)
                ?? 'https://api.shield.land'
        });
    }

    public authByEmail(email: string, password: string):Promise<AxiosResponse<AuthByEmailType>> {
        return this.axios.post(`/api/auth`, {
            email,
            password,
        }, {
            // @ts-ignore
            baseURL: process.env.VUE_APP_API_URL.substring(0, process.env.VUE_APP_API_URL.length - 9)
                ?? 'https://api.shield.land'
        });
    }

    public authByToken():Promise<AxiosResponse<UserAuthInterface>> {
        return this.axios.post(`/auth/token`, {}, {
            baseURL: process.env.VUE_APP_API_URL.substring(0, process.env.VUE_APP_API_URL.length - 5)
                ?? 'https://api.shield.land'
        });
    }
}

export default new Auth();
