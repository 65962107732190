import axios, { AxiosInstance } from "axios";
const Cookies = require('js-cookie')

export default class BaseApi {
    protected axios:AxiosInstance;

    constructor() {
        this.axios = axios.create({
            withCredentials: true,
            baseURL: process.env.VUE_APP_API_URL ?? 'https://api.shield.land/api/admin',
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}`,
            },
        })
    }

}
