import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { checkAuthToken } from "@/middleware/checkAuthToken";
import rightScores from "@/data/rightScores";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/*"home"*/ '../views/HomeView.vue'),
    meta: {
      rightScore: rightScores.moderator,
    },
  },
  {
    path: '/users/list/',
    name: 'usersList',
    component: () => import(/*"usersList" */ '../views/users/ListView.vue'),
    meta: {
      rightScore: rightScores.founder,
    },
  },
  {
    path: '/users/list/:id',
    name: 'userItem',
    component: () => import(/*"usersItem" */ '../views/users/UserItem.vue'),
    meta: {
      rightScore: rightScores.founder,
    },
  },
  {
    path: '/users/blogs/',
    name: 'usersBlogs',
    component: () => import(/*"usersBlogs" */ '../views/users/BlogsView.vue'),
    meta: {
      rightScore: rightScores.founder,
    },
  },
  {
    path: '/users/blogs/:id',
    name: 'usersBlogsItem',
    component: () => import(/*"usersBlogItem" */ '../views/users/BlogItem.vue'),
    meta: {
      rightScore: rightScores.founder,
    },
  },
  {
    path: '/posts/',
    name: 'posts',
    component: () => import(/*"posts" */ '../views/PostsView.vue'),
    meta: {
      rightScore: rightScores.founder,
    },
  },
  {
    path: '/sale/referals/',
    name: 'saleReferals',
    component: () => import(/*"saleReferals" */ '../views/sale/ReferalsView.vue'),
    meta: {
      rightScore: rightScores.admin,
    },
  },
  {
    path: '/sale/list/',
    name: 'saleList',
    component: () => import(/*"saleList" */ '../views/sale/ListView.vue'),
    meta: {
      rightScore: rightScores.referal,
    },
  },
  {
    path: '/sale/orders/',
    name: 'saleOrders',
    component: () => import(/*"saleOrders" */ '../views/sale/OrdersView.vue'),
    meta: {
      rightScore: rightScores.referal,
    },
  },
  {
    path: '/sale/payouts/accounts/',
    name: 'salePayoutsAccounts',
    component: () => import(/*salePayoutsAccounts*/'../views/sale/PayoutsAccounts.vue'),
    meta: {
      rightScore: rightScores.admin
    },
  },
  {
    path: '/sale/payouts/accounts/new/',
    name: 'salePayoutsAccountsNew',
    component: () => import(/*salePayoutsAccountsNew*/'../views/sale/PayoutsAccountsNew.vue'),
    meta: {
      rightScore: rightScores.admin
    },
  },
  {
    path: '/sale/payouts/accounts/:id',
    name: 'salePayoutsAccountsDetail',
    component: () => import(/*PayoutsAccountsDetail*/'../views/sale/PayoutsAccountsDetail.vue'),
    meta: {
      rightScore: rightScores.admin
    },
  },
  {
    path: '/sale/payouts/',
    name: 'salePayouts',
    component: () => import(/*salePayouts*/'../views/sale/Payouts.vue'),
    meta: {
      rightScore: rightScores.admin
    },
  },
  {
    path: '/sale/payouts/new/',
    name: 'salePayoutsNew',
    component: () => import(/*salePayoutsNew*/'../views/sale/PayoutsNew.vue'),
    meta: {
      rightScore: rightScores.admin
    },
  },
  {
    path: '/sale/payouts/:id/',
    name: 'salePayoutsDetail',
    component: () => import(/*salePayoutsDetail*/'../views/sale/PayoutsDetail.vue'),
    meta: {
      rightScore: rightScores.admin
    },
  },
  {
    path: '/sale/orders/:item',
    name: 'saleOrderItem',
    component: () => import(/*"saleOrderItem" */ '../views/sale/OrderItemView.vue'),
    meta: {
      rightScore: rightScores.referal
    },
  },
  {
    path: '/referals/',
    name: 'referalList',
    component: () => import(/*"referalList" */ '../views/referals/ListView.vue'),
    meta: {
      rightScore: rightScores.founder
    },
  },
  {
    path: '/referals/dashboard/',
    name: 'referalDashboard',
    component: () => import(/*"referalDashboard" */ '../views/referals/DetailView.vue'),
    meta: {
      rightScore: rightScores.referal
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(checkAuthToken)

export default router;
