import BaseApi from "@/api/BaseUrl";

class Finance extends BaseApi {
    constructor() {
        super();
    }

    getList(month: number, year: number) {
        return this.axios.get(`/fin-report/table-info/${month}/${year}/`);
    }
}

export default new Finance();
