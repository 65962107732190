
import { defineAsyncComponent } from "vue";
import { Options, Vue } from 'vue-class-component';
import { useStore } from "vuex";
import { key } from "@/store";
const Default = defineAsyncComponent(() => import("@/layouts/default.vue"));
const Plug = defineAsyncComponent(() => import('@/layouts/plug.vue'))
import BaseModal from "@/components/modals/BaseModal.vue";


@Options({
  components: {
    default: Default,
    plug: Plug,
    BaseModal,
  },
})
export default class App extends Vue {
  private store = useStore(key);

  get layoutName(): string {
    return this.store.state.layout;
  }
}
