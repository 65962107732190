
import { Options, Vue } from 'vue-class-component';
import { useStore } from "vuex";
import { key } from "@/store";
import Loader from "@/components/modals/list/Loader.vue";
import Auth from "@/components/modals/list/Auth.vue";

@Options({
  components: {
    loader: Loader,
    auth: Auth,
  },
  watch: {
    modalName(newValue: string|null): void {
      if (!newValue) {
        document.body.classList.remove('modal-open');
      } else {
        document.body.classList.add('modal-open');
      }
    },
  },
})
export default class BaseModal extends Vue {
  private store = useStore(key);

  get modalName(): string|null {
    return this.store.state.modals.modalName
  }
}
